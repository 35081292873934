import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import { get, map, size } from 'lodash/fp'
import { mapIndexed } from 'helpers'

import { Layout } from 'src/components/Layout'
import { MarkdownContent } from 'src/components/MarkdownContent'

import { PageTitle } from 'src/slices/PageTitle'
import { AccordionList } from 'src/slices/AccordionList'

const FAQPage = ({ data }) => {
  const page = get('file.childMarkdownRemark', data)
  const topics = get('frontmatter.topics', page)

  return (
    <Layout>
      <Helmet>
        <title>{get('frontmatter.title', page)}</title>
      </Helmet>
      <PageTitle>{get('frontmatter.title', page)}</PageTitle>
      {mapIndexed(
        ([index, topic]) => (
          <AccordionList
            key={get('title', topic)}
            heading={get('title', topic)}
            pb={index >= size(topic) - 1 ? [6, 10] : 0}
          >
            {map(
              question => (
                <AccordionList.Accordion
                  key={get('question', question)}
                  heading={get('question', question)}
                >
                  <MarkdownContent markdown={get('answer', question)} />
                </AccordionList.Accordion>
              ),
              get('questions', topic)
            )}
          </AccordionList>
        ),
        topics
      )}
    </Layout>
  )
}

export default FAQPage

export const query = graphql`
  query HelpSupportPage {
    file(relativePath: { eq: "customPages/faq.md" }) {
      childMarkdownRemark {
        frontmatter {
          title
          topics {
            title
            questions {
              question
              answer
            }
          }
        }
      }
    }
  }
`
